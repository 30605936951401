<template>
  <div class="sign" v-loading="loading">
    <back />
    <backup />

    <div class="content">
      <div class="pdf">
        <vue-pdf ref="pdf" v-for="i in numPages" :key="i" :page="i" :src="pdfurl" />
      </div>

      <div class="seal">
        <div class="title">请选择签章</div>
        <div class="empty" v-if="list.length == 0">暂无签章</div>
        <div class="item">
          <div class="img" v-for="(item, index) in list" :key="index" :class="item.id == signatureId ? 'is_ac' : ''" @click="handleClick(item.id)">
            <img :src="item.signatureUrl" width="120" height="120">
            <div class="name">{{ item.signatureTypeName }}</div>
          </div>
        </div>
        <div class="btn">
          <el-button type="primary" @click="handleSubmit">签署</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
export default {
  components: {
    VuePdf,
  },
  data () {
    return {
      loading: false,
      // pdfurl: 'https://xn--fcs331b8yd3xj5in.com/files/contract/三方合作代收代付合同_test.pdf',
      pdfurl: '',
      numPages: null, // pdf 总页数
      list: [],
      contractId: '', // 合同id
      signatureId: '', // 签章id
    };
  },
  created () {
    this.getList()
  },
  mounted () {
    this.getNumPages()
  },
  methods: {
    // 获取签章
    getList () {
      this.loading = true
      this.$axios.get(this.$api.selectSignatureInfoList, {
        params: {
          page: 1,
          pageSize: 999,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.list = res.data.result.list
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    //  计算pdf页码总数
    getNumPages () {
      let url = localStorage.getItem('fileUrl') || ''

      if (url == '') {
        this.$message.error('文件加载失败，请联系管理员')
      } else {
        // let url = 'https://xn--fcs331b8yd3xj5in.com/files/contract/三方合作代收代付合同-new.pdf'
        let loadingTask = VuePdf.createLoadingTask({
          url: url,
          // cMapUrl: "https://unpkg.com/browse/pdfjs-dist@2.2.228/cmaps/",
          cMapUrl: "/public/cmaps/",
          cMapPacked: true,
          CMapReaderFactory
        });

        this.pdfurl = loadingTask

        loadingTask.promise.then((pdf) => {
          this.numPages = pdf.numPages
        })
      }
    },
    handleClick (id) {
      this.signatureId = id
    },
    // 签章
    handleSubmit () {
      if (this.signatureId) {
        this.$axios.post(this.$api.signTendersContract, {
          contractId: this.$route.query.id,
          signatureId: this.signatureId
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message.success('签署成功')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000);
          }
        })
      } else {
        this.$message.error('请选择签章')
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sign {
  min-height: 100vh;

  .content {
    position: relative;
    display: flex;
    height: 100%;

    .pdf {
      width: calc(100% - 320px);
      height: fit-content;
    }

    .seal {
      position: fixed;
      top: 210px;
      right: 20px;
      width: 300px;
      min-height: 300px;
      height: fit-content;
      padding: 20px;
      margin-left: 50px;
      margin-right: 20px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 6px;
      box-sizing: border-box;

      .title {
        color: #1A1A1A;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .empty {
        color: gray;
        height: 200px;
        line-height: 200px;
        text-align: center;
      }

      .item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        .img {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 2px solid #fff;
          border-radius: 10px;
          cursor: pointer;

          .name {
            color: rgba(3, 16, 14, 0.60);
            margin-top: 8px;
          }
        }

        .is_ac {
          border-color: #069BBC;
        }
      }

      .btn {
        margin-top: 50px;
        text-align: center;

        .el-button {
          width: 50%;
        }
      }
    }
  }
}
</style>